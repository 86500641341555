<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="`${$t('orders-notes')}`">
        <template slot="content" slot-scope="{ setLoading }">
          <data-table
            :url="
              `/${$route.params.shop_name}/${$route.params.shop_lang}/orders/notices`
            "
            @loading="setLoading"
          />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox";
import DataTable from "@/components/DataTable/DataTable.vue";

export default {
  name: "OrdersNotesList",
  components: {
    Ibox,
    DataTable
  },
  data() {
    return {};
  }
};
</script>
